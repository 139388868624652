
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/


/*
* BASE DEPENDENCIES
*/

@import
	"ecco";

/*
* SHARED
*/

@import
    "shared/vars",
    "shared/animations";
    
/*
* COMMON BLOCKS
*/

@import 
    "common/_home",
    "common/_homepage-events";

/*
* DESIGN COMPONENTS
*/

@import
	//"blocks/social",
	//"blocks/tags",
	"blocks/card",
    "blocks/board-card",
    "blocks/icon-links",
    "blocks/warning", 
	//"blocks/figure",
    //"blocks/detail";
    "blocks/blender";
