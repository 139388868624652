.icon_links{
    background-color: $icon-links-bg-color;
    text-align: center;
    padding: 4em 1em;
    box-shadow: 0 0 50px rgba(#000000, .2);
    &__heading{
        color: $icon-links-title-color;
        font-size: rem(32px);
    }
    .list{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 0;
        .list__item{
            margin: 1em;
        }
        .list__link{
            display: flex;
            align-items: center;
            font-weight: 700;
            flex-direction: column;
        }
        .list__icon{
            margin-bottom: .5em;
        }
        @include link(".list__link"){
            color: $icon-links-color;
        }
        @include link-over(".list__link"){
            text-decoration: none;
        }
        @for $i from 1 through 4 {
            .list__item:nth-of-type(#{$i}) .list__link{
                &:before{
                    display: block;
                    content: url(#{$img-loc}/icons/icon-links/v-202301240719/#{$i}.png);
                    margin-bottom: .5em;
                }
         	}
        }
    }
    @include min(1024px){
        position: absolute;
        top: 0;
        right: 0;
        min-height: calc(100% - 5vw);
        width: 225px;
        .list{
            flex-direction: column;
        }
    }
    @include min(1280px){
        width: 270px;
    }
}


.document-links{
    text-align: left;
    padding: 0 0 .5em;
    font-family: $font-headings;
    @include min(1024px){
        padding: 1.7em 0 2.6em;
    }
    &__heading{
        font-size: rem(44px);
        margin-bottom: 2.3rem;
    }
    .text-component{
        overflow: visible;
    }
    &__list{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .list__item{
            margin: 0;
            width: 100%;
            @include max(499px){
                &:nth-child(odd){
                    .list__link{
                        background-color: rgba(rgb(241, 240, 240), .7);
                    }
                }
            }
            @include min(500px){
                width: 50%;
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(10){
                    .list__link{
                        background-color: rgba(rgb(241, 240, 240), .7);
                    }
                }
            }
            @include min(800px){
                @include max(1023px){
                    width: 100%;
                    &:nth-child(even){
                        .list__link{
                            background-color: rgba($board-blur-bg, .75);
                        }
                    }
                    &:nth-child(odd){
                        .list__link{
                            background-color: rgba(rgb(241, 240, 240), .7);
                        }
                    }
                }            
            }
        }
        .list__link{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: $font-headings;
            font-weight: 600;
            font-size: rem(36px);
            min-height: 120px;
            background-color: rgba($board-blur-bg, .75);
            padding: .5rem;
            box-sizing: border-box;
            transition: box-shadow .5s ease, background-color .5s ease, color .25s ease;
            @include min(1024px){
                min-height: 150px;
            }
        }
        @include link(".list__link"){
            color: $icon-links-color;
            text-decoration: none;
        }
        @include link-over(".list__link"){
            position: relative;
            z-index: 50;
            background-color: rgba($board-blur-bg, .75)!important; //important kvůli nth-child
            box-shadow: 0 0 40px rgba( #000000,.5);
        }
    }
}